import React from 'react'
import { css, cx } from '@emotion/css'
import ImageBoeuf from '../sectionBoeuf.webp';




const SectionBoeuf = () => {
    return (
        <div className={`h-full ${css`
        position: relative;
    `}`}>
            <div className={` ${css`
            background-image: url("${ImageBoeuf}");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            width: 100%;
            height: 2200px;
            padding: 56px;
        `}`} >
                <h1 style={{
                    textAlign: "center",
                    padding: "54px",
                    fontSize: "62px",
                    marginTop: "-5%",

                }} className="titlePromotions">Nos produits</h1>

                <div className="products">
                    <div className="product">
                        <div className="headProduct">
                            <h2>Ron degite</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">12,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Côte de boeuf</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">17,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Bifteck haché</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">12,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Rumsteck</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">16,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Filet de boeuf</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">24,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Faux-filet de boeuf</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">18,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Noix</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">15,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Tende de tranche de boeuf</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">14,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Epaule d'agneau</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">14,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Gigot entier d'agneau</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">15,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Côte d'agneau 1kg</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">15,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Côte filet de veau 1kg</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">16,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Jarret de boeuf</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">10,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Tendron de veau</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">16,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Foie de poulet 1kg</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">6,50€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Rognons rouge 1kg</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">9,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Foie de boeuf 1kg</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">11,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Blanc de poulet 1kg</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">9,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Merguez de boeuf 1kg</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">10,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Plat de côte de bœuf 1kg</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">9,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Basse côte de bœuf 1kg</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">10,90€</button>
                    </div>
                    <div className="product">
                        <div className="headProduct">
                            <h2>Pilons 1kg</h2>
                        </div>
                        <div className="bodyProduct">

                        </div>
                        <button className="btnProduct">5,90€</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionBoeuf