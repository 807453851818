import React from 'react'
import { css, cx } from '@emotion/css'

import SectionInfoImage from '../sectionInfo.webp';
const SectionInfo = () => {
    return (
        <div className={`h-full ${css`
            position: relative;
        `}`}>
            <div className={` ${css`
                background:linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("${SectionInfoImage}");
                background-repeat: no-repeat;
                background-attachment: fixed;
                background-position: center;
                background-size: cover;
                width: 100%;
                height: 100vh;
            `}`} >
                <br />
                <div className={` ${css`
                    color: white;
                    padding: 32px;
                    background-color: #C61C1C;
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                    width: 80%;
                    max-width: 800px;
                    font-size: 32px;
                    text-align: left;
                    box-shadow: 0 2.8px 2.2px rgba(220, 20, 60, 0.034), 0 6.7px 5.3px rgba(220, 20, 60, 0.048), 0 12.5px 10px rgba(220, 20, 60, 0.06), 0 22.3px 17.9px rgba(220, 20, 60, 0.072), 0 41.8px 33.4px rgba(220, 20, 60, 0.086), 0 100px 80px rgba(220, 20, 60, 0.12);
                `}`} >
                    Ouverture de la première Boucherie
                    Halal en centre ville de Nantes

                    <iframe style={{
                        width: "100%",
                        height: "450px"
                    }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2710.269174752922!2d-1.5633686841320085!3d47.211315223192834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4805edb8ad8c7c65%3A0x6b7215415caa206b!2sBoucherie%20Djen%20Djen!5e0!3m2!1sfr!2sfr!4v1652501104640!5m2!1sfr!2sfr"> </iframe>
                    <h1>Horaire d'ouverture :</h1>
                    <div style={{
                    }}>
                        <p>Tout les jours de 8h à 22h</p>
                        <p>Vendredi: de 8 à 12 h 45, de 14 à 22 heures.</p>
                    </div>

                </div>


            </div>
        </div>
    )
}

export default SectionInfo