import React from 'react'
import ImageContact from '../imageContact.jpg';
import { css, cx } from '@emotion/css'

const Contact = () => {
    return (
        <div className={` ${css`
               background-color: black;
                width: 100%;
                
            `}`} >
            <div className={` ${css`
            display: flex;
            gap: 50px;
            @media (max-width: 780px) {
                flex-direction: column;
            }
            `}`}>
                <div className="blockGauche" style={{
                    width: "60%",
                    color: "white",
                    marginTop: "150px",
                    marginLeft: "20%",
                    marginBottom: "20px"
                }}>
                    <div className="header">
                        <h1>Une commande spécifique ?</h1>
                        <p>Vous êtes un professionnel ou un particulier qui désire une commande précise, vous pouvez nous contacter avec les détails de votre demande ainsi que les informations pour vous contacter.
                        </p>
                    </div>

                    <div className="info">
                        <h3>Adresse</h3>
                        <p>21 quai de la fosse</p>
                        <h3>Mail</h3>
                        <p>rm.food44@hotmail.com</p>
                        <h3>Téléphone</h3>
                        <p>09 82 56 91 00</p>
                    </div>
                </div>

                <div className="blockDroite"
                    style={{
                        width: "100%",
                        padding: "24px",
                        marginTop: "150px",
                        marginRight: "20%",
                        marginBottom: "220px"
                    }}
                >
                    <form action="" style={{
                        display: "flex",
                        flexDirection: "column"

                    }}>
                        <label style={{ color: "white" }} htmlFor="name">Nom</label>
                        <input type="text" name="name" placeholder='Entrer votre nom'
                            style={{
                                backgroundColor: "rgba(0,0,0, 0.1)",
                                color: "white",
                                border: "2px",
                                borderBottom: "solid",
                            }} />
                        <label htmlFor="mail" style={{ color: "white" }}>Adresse mail</label>
                        <input type="mail" name="mail" placeholder='Entrer votre adresse mail'
                            style={{
                                backgroundColor: "rgba(0,0,0, 0.1)",
                                color: "white",
                                border: "2px",
                                borderBottom: "solid",
                            }} />
                        <label htmlFor="message" style={{ color: "white" }}>Votre message</label>
                        <textarea name="message" cols="30" rows="10" style={{
                            backgroundColor: "rgba(0,0,0, 0.1)",
                            color: "white",
                            border: "2px",
                            borderBottom: "solid",
                            padding: 20,
                        }}></textarea>
                        <button className={` ${css`
                        padding: 20px;
                        background-color:  #C61C1C;
                       
                        border-radius: 100px;
                        transition: all .2s;
                        margin-top: 50px;
                        &:hover {
                            box-shadow: 0 2.8px 2.2px rgba(220, 255, 255, 0.034), 0 6.7px 5.3px rgba(220, 255, 60, 0.048), 0 12.5px 10px rgba(255, 250, 60, 0.06), 0 22.3px 17.9px rgba(220, 20, 60, 0.072), 0 41.8px 33.4px rgba(220, 20, 60, 0.086), 0 100px 80px rgba(220, 20, 60, 0.12),  0 100px 80px rgba(255, 255, 255, 0.12);
                            background-color:  white;
                            color: black;
                        }
                `}`} >Envoyer</button>

                    </form>
                </div>
            </div>


        </div>
    )
}

export default Contact