import React from 'react'
import { css, cx } from '@emotion/css'
import Logo from '../logo.svg';
import IconShop from '../iconShop.svg';
const Navbar = () => {
  return (
    <div>
      <div
        className={css`
          padding: 12px;
          background-color: #C61C1C;
          color: white;
          box-shadow: 0 2.8px 2.2px rgba(220, 20, 60, 0.034), 0 6.7px 5.3px rgba(220, 20, 60, 0.048), 0 12.5px 10px rgba(220, 20, 60, 0.06), 0 22.3px 17.9px rgba(220, 20, 60, 0.072), 0 41.8px 33.4px rgba(220, 20, 60, 0.086), 0 100px 80px rgba(220, 20, 60, 0.12),  0 100px 80px rgba(255, 255, 255, 0.12);
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-content: center;
          align-items: center;
          position: relative;
          opacity: 0.96;
          z-index: 10;
    `}
      >
        <img src={Logo} className={css`
        width: 120px;
      `} />
        <ul className={css`
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        gap: 20px;
      `}>
          <li>ACCUEIL</li>
          <li>MENU</li>
          <li className={`${css`
                background-color: white;
                padding: 10px;
                border-radius: 100%;
                box-shadow: 0 2.8px 2.2px rgba(255, 255, 255, 0.034), 0 6.7px 5.3px rgba(255, 255, 255, 0.048), 0 12.5px 10px rgba(255, 255, 255, 0.06), 0 22.3px 17.9px rgba(255, 255, 255, 0.072), 0 100px 80px rgba(255, 255, 255, 0.12);
            `}`}>
            <img src={IconShop} style={{
              width: "40px",
            }} />
          </li>
        </ul>


      </div>

    </div>

  )
}


export default Navbar;