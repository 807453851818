import React from 'react'
import { css, cx } from '@emotion/css'
import ImageDetails from '../sectionDetails.webp';

const SectionDetails = () => {
    return (
        <div className={`h-full ${css`
        position: relative;
    `}`}>
            <div className={` ${css`
                background-image: url("${ImageDetails}");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 50%;
                width: 100%;
                height: 200vh;
            `}`} >
                <div className={` ${css`
                    position: absolute;
                    color: white;
                    padding: 32px;
                    background-color: rgba(0,0,0, 0.8);
                    top: 13%;
                    display: flex;
                    flex-direction: column;
                    margin-left: 5%;
                    margin-right: 5%;
                    text-align: center;
                    width: 40%;
                    font-size: 24px;
                    text-align: left;
                    @media (max-width: 1100px) {
                        margin-left: auto;
                        margin-right: auto;
                        width: 80%;
                        top: 8%;
                        left: 5px;
                        right: 5px;
                      }
                `}`} >
                    <span style={{ fontSize: "42px" }}>Notre viande est issue à 70% de la region <span style={{ padding: "5px", backgroundColor: "#C61C1C", transform: "rotate(130deg)" }}>Pays de Loire</span> et à 30% du département des deux sèvres.</span>  L'ensemble des bovins à destination du marché Halal sont donc proches de notre atelier de transformation.
                    Afin de certifier une viande halal de qualité, répondant aux attentes de nos clients, nous travaillons en étroite relation avec notre partenaire indépendant VHP.
                    Pour vous servir nous sélectionnons uniquement des races françaises qui incarne la qualité. Comme le Limousin, le Blond d'Aquitaine, le Parthenais ou encore le Charolais.
                    Enfin, nous nous appuyons sur des savoirs et une experience historique pour garantir la qualité, à tout point de vu, jusqu'à la livraison chez nos clients.
                    <button className={` ${css`
                        padding: 20px;
                        background-color:  #C61C1C;
                        box-shadow: 0 2.8px 2.2px rgba(220, 20, 60, 0.034), 0 6.7px 5.3px rgba(220, 20, 60, 0.048), 0 12.5px 10px rgba(220, 20, 60, 0.06), 0 22.3px 17.9px rgba(220, 20, 60, 0.072), 0 41.8px 33.4px rgba(220, 20, 60, 0.086), 0 100px 80px rgba(220, 20, 60, 0.12),  0 100px 80px rgba(255, 255, 255, 0.12);
                        border-radius: 100px;
                        transition: all .2s;
                        margin-top: 50px;
                        &:hover {
                            box-shadow: 0 2.8px 2.2px rgba(220, 255, 255, 0.034), 0 6.7px 5.3px rgba(220, 255, 60, 0.048), 0 12.5px 10px rgba(255, 250, 60, 0.06), 0 22.3px 17.9px rgba(220, 20, 60, 0.072), 0 41.8px 33.4px rgba(220, 20, 60, 0.086), 0 100px 80px rgba(220, 20, 60, 0.12),  0 100px 80px rgba(255, 255, 255, 0.12);
                            background-color:  white;
                            color: black;
                        }
                `}`} >Voir en détails</button>

                </div>
            </div>
        </div>
    )
}

export default SectionDetails