import './App.css';
import Navbar from './components/Navbar';
import { css, cx } from '@emotion/css'
import Hero from './components/Hero';
import SectionInfo from './components/SectionInfo';
import SectionBoeuf from './components/SectionBoeuf';
import SectionDetails from './components/SectionDetails';
import Contact from './components/Contact';
import { Suspense } from 'react';
import SectionAvis from './components/SectionAvis';

function App() {
  return (
    <div className="App">
      <Suspense fallback={<h1>Chargement...</h1>}>
        <Navbar />
        <Hero />
        <SectionInfo />
        <SectionBoeuf />
        <SectionDetails />
        <Contact />
      </Suspense>

    </div>

  );
}

export default App;
