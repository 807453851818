import React from 'react'
import HeroImg from '../hero.webp';
import HeroDjenDjen from '../HeroDjenDjen.svg';
import iconDown from '../iconDown.svg';
import { css, cx } from '@emotion/css'

const Hero = () => {
    return (
        <div className={`h-full ${css`
            margin-top: -80px;
            position: relative;
            box-shadow: 0 2.8px 2.2px rgba(220, 20, 60, 0.034), 0 6.7px 5.3px rgba(220, 20, 60, 0.048), 0 12.5px 10px rgba(220, 20, 60, 0.06), 0 22.3px 17.9px rgba(220, 20, 60, 0.072), 0 41.8px 33.4px rgba(220, 20, 60, 0.086), 0 100px 80px rgba(220, 20, 60, 0.12),  0 100px 80px rgba(255, 255, 255, 0.12);

        `}`}>
            <div className={` ${css`
                background:linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("${HeroImg}");
                background-repeat: no-repeat;
                background-attachment: fixed;
                background-position: center;
                background-size: cover;

                width: 100%;
                height: 100vh;
            `}`} >

                <img src={HeroDjenDjen} className={` ${css`
                position: absolute;
                top: 33%;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                text-align: center;
                width: 550px;
            `}`} />


                <img src={iconDown} className={` ${css`
                position: absolute;
                bottom: 13%;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                width: 80px;
            `}`} />

            </div>
        </div>

    )
}

export default Hero